import useJwt from "@/auth/jwt/useJwt";
const axios = useJwt.axiosIns;

export async function getSettlements() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/settlement`);
  return res.data;
}

export async function getSettlement(settlementId) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/settlement/${settlementId}`);
  return res.data;
}

export async function updateSettlement(data) {
  const formData = new FormData();
  formData.append("file", data.attachment);
  formData.append("id", data.id);
  formData.append("status", data.status);
  formData.append("rate", data.rate);

  await axios.put(`${process.env.VUE_APP_API_URL}/settlement`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
